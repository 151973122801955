import React, { useState } from 'react';
import { Field, Formik, Form } from 'formik';
import { Option } from 'react-select';
import * as Yup from 'yup';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import {
    SelectField,
    TextInputField,
    SubmitButton,
    ErrorMessage,
    Title,
    Icon,
} from 'components';
import { PanelContent, PanelFooter } from 'components/Panel';
import isEqual from 'lodash/isEqual';
import ApolloErrors from 'services/apollo/errors';
import styled from 'styled-components';
import { listEmployees_list_edges_node_Employee as IEmployee, IEmployeeHoldingList, IEmployeeRole } from 'types/listEmployees';
import DropdownHolding from 'feature/DropdownHolding';
import { EmployeeRole } from 'types/globalTypes';
import { FieldWrapper } from '../EmployeesPage.style';
import { isTestingEnvironment } from 'utils';
import { checkIfAllHoldingsRole } from '../EmployeeUtils';
import DropdownChecklistSites from 'feature/DropdownChecklistSites';

const FORM_ID = 'employee_edit_add_form';

enum ERRORS {
    ALREADY_TAKEN = 'error:email.ALREADY_TAKEN',
}

type ErrorType = keyof typeof ERRORS;

const allowedEmailDomains = isTestingEnvironment() ? /\@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/ : /\@(compass-group.fr)$/;

const getSchema = (t: any) =>
    Yup.object().shape({
        lastName: Yup.string().typeError(t('app:error.required')).required(t('app:error.required')),
        firstName: Yup.string().required(t('app:error.required')),
        role: Yup.string().required(t('app:error.required')),
        holdingList: Yup.array().when('role', {
            is: (role) => role !== EmployeeRole.ChecklistAdmin,
            then: Yup.array().of(
                Yup.object().shape({
                    value: Yup.string().required(t('app:error.required')),
                    label: Yup.string().required(t('app:error.required'))
                })
            ).min(1)
        }),
        siteList: Yup.array().when('role', {
            is: (role) => role === EmployeeRole.ChecklistAdmin,
            then: Yup.array().of(
                Yup.object().shape({
                    value: Yup.string().required(t('app:error.required')),
                    label: Yup.string().required(t('app:error.required'))
                })
            ).min(1)
        }),
        email: Yup.string().required(t('app:error.required'))
            .email(t('app:error.validEmail'))
            .matches(allowedEmailDomains, t('app:error.validEmployeeEmail'))
    });

interface IAddOrEditEmployeeFormProps {
    employee?: IEmployee | null;
    updateEmployee: Function;
    onSuccess?: Function;
    isCreation?: boolean;
}

const AddOrEditEmployeeForm = ({ employee, updateEmployee, onSuccess, isCreation }: IAddOrEditEmployeeFormProps) => {
    const [error, setError] = useState('');
    const [errorKey, setErrorKey] = useState('');
    const [t] = useTranslation();

    const isEditing = !!employee;

    const employeesRoleFieldValues = Object.keys(EmployeeRole).map((role) => {
        return { 
            id: EmployeeRole[role],
            label: i18next.t(`schema:employee.roles.${EmployeeRole[role]}`)
        }
    });

    const employeeHoldings = employee ? 
        employee.holdingList.edges.map(holding => ({ label: holding.node.name, value: holding.node.id })) :
        [];
    const employeeSites = employee ? 
        employee.siteList.map(site => ({ label: site.siteName, value: site.siteId })) :
        [];

    const allHoldings = [{label:t('page:admin.employees.allHoldings').toUpperCase(), value: "0"}];
    const holdingListInitialValues = checkIfAllHoldingsRole(employee?.role) ? 
    allHoldings : 
        employeeHoldings;
        
    return (
        <Formik
            isInitialValid={isEditing}
            validationSchema={getSchema(t)}
            validateOnBlur={false}
            validateOnChange={true}
            initialValues={{
                ...employee,
                holdingList: holdingListInitialValues,
                siteList: employeeSites,
            }}
            onSubmit={async (values, { setSubmitting }) => {
                const {id, firstName, lastName, role, holdingList, siteList, email} = values;

                const currentHoldingList: IEmployeeHoldingList[] = holdingList ?? [];
                const currentSiteList: IEmployeeHoldingList[] = siteList ?? [];
                const formatedHoldingList = currentHoldingList.reduce((holdingList: string[], employee) => {
                    employee.value !== "0" && holdingList.push(employee.value);
                    return holdingList;
                }, []);

                const formatedSiteList = currentSiteList.reduce((siteList: string[], employee) => {
                    employee.value !== "0" && siteList.push(employee.value);
                    return siteList;
                }, []);

                const initialInput = { 
                    id, 
                    firstName,
                    lastName,
                    role,
                    holdings: formatedHoldingList,
                    sites: formatedSiteList
                };

                const input = isCreation ? {...initialInput, email} : initialInput;

                await updateEmployee({
                    variables: {
                        input
                    },
                })
                    .then(({ errors }: any) => {
                        if (errors) {
                            throw errors;
                        }
                        if (onSuccess) {
                            setError('');
                            setErrorKey('');
                            onSuccess();
                        }
                        setSubmitting(false);
                    })
                    .catch((errs: any) => {
                        const errors: string[] = ApolloErrors.errorsMessages(errs);
                        const errorKey: ErrorType = (errors[0] || '') as ErrorType;

                        setErrorKey(errorKey);
                        setError(t(ERRORS[errorKey] || errs.message) || '');

                        setSubmitting(false);
                        return;
                    });
            }}
        >
            {({ errors, isSubmitting, values, initialValues, isValid, setFieldValue }) => {
                return (
                    <>
                        <PanelContent>
                            <Form id={FORM_ID}>
                                <Title
                                    mode="H3"
                                    value={t('page:admin.employees.general')}
                                    icon={<Icon.Cog />}
                                />
                                <Field
                                    label={t('schema:employee.lastName')}
                                    name="lastName"
                                    component={TextInputField}
                                />
                                <Field
                                    label={t('schema:employee.firstName')}
                                    name="firstName"
                                    component={TextInputField}
                                />
                                <Field
                                    label={t('schema:employee.email')}
                                    name="email"
                                    component={TextInputField}
                                    disabled={isEditing}
                                    onChange={({value}) => {
                                        setFieldValue && setFieldValue('email', value);
                                        setError('');
                                        setErrorKey('');
                                    }}
                                />
                                {error && errorKey === 'ALREADY_TAKEN' ? (
                                    <ErrorMessagePadded>{error}</ErrorMessagePadded>
                                ) : null}
                                <Field
                                    label={t('schema:employee.role')}
                                    name="role"
                                    component={SelectField}
                                    data={employeesRoleFieldValues}
                                    onChange={(value: IEmployeeRole) => {
                                        if (checkIfAllHoldingsRole(value.id)) {
                                            setFieldValue && setFieldValue('holdingList', allHoldings);
                                        } else {
                                            setFieldValue && setFieldValue('holdingList', employeeHoldings); 
                                        }
                                    }}
                                    fullWidth
                                />
                                {values.role !== EmployeeRole.ChecklistAdmin &&
                                    <FieldWrapper>
                                        <Field
                                            label={t('schema:employee.holdings')}
                                            name="holdingList"
                                            isMultiSelect
                                            component={DropdownHolding}
                                            onChange={(option: Option | Option[]) => {
                                                setFieldValue && setFieldValue('holdingList', option);
                                            }}
                                            initialValues={values.holdingList}
                                            overrideShowRequiredAsterisk
                                            isDisabled={checkIfAllHoldingsRole(values.role)}
                                        />
                                    </FieldWrapper>
                                }
                                {values.role === EmployeeRole.ChecklistAdmin && 
                                    <FieldWrapper>
                                        <Field
                                            label={t('schema:employee.holdings')}
                                            name="siteList"
                                            isMultiSelect
                                            component={DropdownChecklistSites}
                                            onChange={(option: Option | Option[]) => {
                                                setFieldValue && setFieldValue('siteList', option);
                                            }}
                                            initialValues={values.siteList}
                                            overrideShowRequiredAsterisk
                                        />
                                </FieldWrapper>
                                }
                            </Form>
                        </PanelContent>
                        <PanelFooter>
                            <SubmitButton
                                formHasNoChange={isEditing && isEqual(values, initialValues)}
                                form={FORM_ID}
                                disabled={
                                    !isValid ||
                                    (isEditing && isEqual(values, initialValues)) ||
                                    Object.entries(errors).length !== 0 ||
                                    isSubmitting
                                }
                            />
                        </PanelFooter>
                    </>
                );
            }}
        </Formik>
    );
};

const ErrorMessagePadded = styled(ErrorMessage)`
    margin: 0 0 20px;
`;

export default AddOrEditEmployeeForm;
